<template>
  <aw-tabler :table="tableJSON">
    <template #filter>
      <el-form
        :model="tableJSON.filter"
        label-suffix="："
        inline
      >
        <el-form-item label="用户ID" prop="id">
          <el-input v-model="tableJSON.filter.id" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="用户昵称" prop="nickname">
          <el-input v-model="tableJSON.filter.nickname" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="授权手机号" prop="mobile">
          <el-input v-model="tableJSON.filter.mobile" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="店铺名" prop="store_name">
          <el-input v-model="tableJSON.filter.store_name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="法人姓名" prop="realname">
          <el-input v-model="tableJSON.filter.realname" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="联系手机号" prop="phone">
          <el-input v-model="tableJSON.filter.phone" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="地区" prop="area_code">
          <el-cascader
            v-model="tableJSON.filter.area_code"
            :options="areaCode"
            :props="{
              emitPath: false,
              value: 'value',
              label: 'label'
            }"
            clearable
          />
        </el-form-item>
        <el-form-item label="营业执照号" prop="business_license_txt">
          <el-input v-model="tableJSON.filter.business_license_txt" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </template>
    <template #drawercontent>
      <el-tabs v-model="drawerTabs" type="card">
        <el-tab-pane label="基础信息" name="basic">
          <el-form
            class="drawer-form disabled"
            ref="drawerFormEle"
            :model="drawerForm"
            label-width="120px"
            label-suffix="："
            hide-required-asterisk
            disabled
          >
            <h3 class="h3-tit">
              <span class="text">用户基本信息</span>
            </h3>
            <el-form-item label="用户ID" prop="id">{{drawerForm.id}}</el-form-item>
            <el-form-item label="用户昵称" prop="nickname">
              <span>{{drawerForm.nickname}}</span>
            </el-form-item>
            <el-form-item label="授权手机号" prop="mobile">{{ drawerForm.mobile }}</el-form-item>
            <h3 class="h3-tit">
              <span class="text">代理商信息</span>
            </h3>
            <el-form-item label="代理商" prop="inviter">
              <p>{{ `${drawerForm.name} - ${drawerForm.username}` }}</p>
            </el-form-item>
            <el-form-item label="邀请码" prop="agent_id">{{ drawerForm.pull_code || '无' }}</el-form-item>
            <h3 class="h3-tit">
              <span class="text">认证信息</span>
              <em>注：认证信息全部填写，才算认证成功</em>
            </h3>
            
            <!--2期新增&改动 2023.11.23-->
            <el-form-item label="店铺名" prop="store_name">
              {{ drawerForm.store_name }}
            </el-form-item>
            <el-form-item label="店铺门头照" prop="store_head_image">
              <template v-for="(img, i) in drawerForm.store_head_image.split(',')" :key="i">
                <el-image
                  v-if="img"
                  class="img-viewer"
                  fit="contain"
                  :src="img"
                  :initial-index="i"
                  :preview-src-list="drawerForm.store_head_image.split(',')"
                />
              </template>
            </el-form-item>
            <el-form-item label="店铺环境照" prop="store_image">
              <template v-for="(img, i) in drawerForm.store_image.split(',')" :key="i">
                <el-image
                  v-if="img"
                  class="img-viewer"
                  fit="contain"
                  :src="img"
                  :initial-index="i"
                  :preview-src-list="drawerForm.store_image.split(',')"
                />
              </template>
            </el-form-item>
            <el-form-item label="地区" prop="area_code">
              {{drawerForm.area_code_name}}
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              {{drawerForm.address}}
            </el-form-item>
            <el-form-item label="营业执照" prop="business_license">
              <el-image
                v-if="drawerForm.business_license"
                class="img-viewer"
                fit="contain"
                :src="drawerForm.business_license"
                :preview-src-list="[drawerForm.business_license]"
              />
            </el-form-item>
            <el-form-item label="营业执照编号" prop="business_license_txt">
              {{ drawerForm.business_license_txt }}
            </el-form-item>
            
            <el-form-item label="法人姓名" prop="realname">
              <span>{{drawerForm.realname}}</span>
            </el-form-item>
            <el-form-item label="联系手机号" prop="phone">
              <span>{{drawerForm.phone}}</span>
            </el-form-item>
            
            
            <el-form-item label="身份证正面" prop="id_card_front">
              <el-image
                v-if="drawerForm.id_card_front"
                class="img-viewer"
                fit="contain"
                :src="drawerForm.id_card_front"
                :preview-src-list="[drawerForm.id_card_front]"
              />
            </el-form-item>
            <el-form-item label="身份证反面" prop="id_card_back">
              <el-image
                v-if="drawerForm.id_card_back"
                class="img-viewer"
                fit="contain"
                :src="drawerForm.id_card_back"
                :preview-src-list="[drawerForm.id_card_back]"
              />
            </el-form-item>
          </el-form>
          <el-button style="margin-left: 120px" @click="closeDrawer">返回</el-button>
        </el-tab-pane>
        <el-tab-pane label="收货地址" name="address">
          
          <el-table
            style="margin: 15px 0"
            :data="addressList"
            border
          >
            <el-table-column label="收货人" prop="name" />
            <el-table-column label="手机号" prop="mobile" />
            <el-table-column label="地址" prop="areaStr"/>
            <el-table-column label="详细地址" prop="address" />
            <el-table-column label="默认" width="90" prop="isdefault">
              <template #default="{row}">
                {{ row.isdefault === 1 ? '是' : '否' }}
              </template>
            </el-table-column>
          </el-table>
          <el-button @click="closeDrawer">返回</el-button>
        
        </el-tab-pane>
      </el-tabs>
    
    </template>
  </aw-tabler>
</template>

<script setup lang="jsx">
import {ref} from 'vue'
import {http} from "@/assets/js"
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {useRoute} from 'vue-router'
import areaCode from '@/assets/json/areacode.json'

const route = useRoute()

const drawerTabs = ref('basic')
const drawerForm = ref({
  id: '',
  nickname: '',
  mobile: '', // 授权手机号
  name: '',
  username: '',
  pull_code: '', // 邀请码
  store_name: '', // 店铺名
  store_head_image: '', // 店铺门头照
  store_image: '', // 店铺环境照
  area_code_name: '', // 地区名称
  address: '', // 详细地址
  business_license: '', // 营业执照
  business_license_txt: '', // 营业执照编号
  realname: '', // 法人姓名
  phone: '', // 联系手机号
  id_card_front: '', // 身份证正面
  id_card_back: '', // 身份证反面
})

const drawerLoading = ref(false)
const getUserInfo = (data) => {
  drawerLoading.value = true
  drawerForm.value.agent_id = data.agent_id
  http.post(`/agent/user/info`, {id: data.id}).then(res => {
    if(res) {
      drawerForm.value = res
      getAddressList()
    }
  }).finally(() => {
    drawerLoading.value = false
  })
}


// 收货地址
const addressList = ref([])
const getAddressList = () => {
  http.post(`/agent/user/addressList?uid=${drawerForm.value.id}`).then(res => {
    if(res && Array.isArray(res.data)) {
      res.data.map(item => {
        if(!item.area) item.area = item.city
      })
      addressList.value = res.data
    }
  })
}

const tableJSON = ref({
  title: '用户管理',
  loading: false,
  filter: {
    id: '',
    nickname: '',
    mobile: '',
    store_name: '', // 店铺名
    realname: '', // 法人姓名
    phone: '', // 联系手机号
    area_code: '', // 地区
    business_license_txt: '', // 营业执照
  },
  datas: [],
  columns: [
    ...tableColumn([
      {
        title: '用户ID',
        key: 'id'
      },
      {
        title: '用户昵称',
        key: 'nickname',
        minWidth: '140x'
      },
      {
        title: '授权手机号',
        key: 'mobile',
        minWidth: '140px'
      },
      {
        title: '店铺名',
        key: 'store_name',
        minWidth: '120px'
      },
      {
        title: '法人姓名',
        key: 'realname',
        minWidth: '130px'
      },
      {
        title: '联系手机号',
        key: 'phone',
        width: '160px'
      },
      {
        title: '省市区',
        key: 'show_name',
        minWidth: '140px'
      },
      {
        title: '营业执照号',
        key: 'business_license_txt',
        minWidth: '140px'
      },
      {
        title: '是否认证',
        key: 'status_name',
        minWidth: '90px'
      },
    ]),
    {
      fixed: 'right',
      title: '操作',
      key: 'act',
      width: '100px',
      buttons: [
        {
          name: '查看',
          props: { type: 'primary' },
          show: () => true,
          action: (data) => {
            tableJSON.value.drawer.title = '详情'
            tableJSON.value.drawer.show = true
            // drawerFormType.value = 'show'
            getUserInfo(data)
          }
        },
        // {
        //   name: '编辑',
        //   props: { type: 'primary' },
        //   show: () => true,
        //   action: (data) => {
        //     tableJSON.value.drawer.title = '修改'
        //     tableJSON.value.drawer.show = true
        //     drawerFormType.value = 'edit'
        //     getUserInfo(data)
        //   }
        // }
      ]
    }
  ],
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  action: async (params) => {
    tableJSON.value.loading = true
    let page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
    http.post('/agent/user/index', {
      ...params,
      ...route.query,
      ...page
    }).then(result => {
      tableJSON.value.datas = result.data
      tableJSON.value.page.total = result.total || Infinity
      tableJSON.value.page.page = result.current_page
      tableJSON.value.page.page_size = result.per_page
    }).finally(() => {
      tableJSON.value.loading = false
    })
  },
  drawer: {
    show: false,
    title: '',
    size: '70%',
    close: () => {
      closeDrawer()
    }
  }
})

const closeDrawer = () => {
  drawerTabs.value = 'basic'
  tableJSON.value.drawer.show = false
  tableJSON.value.drawer.title = ''
}
</script>

<style lang="scss" scoped>
.drawer-form{
  padding-right: 20px;
  :deep(.el-select),
  :deep(.el-cascader){
    width: 100%;
  }
  
  .img-viewer{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid var(--el-border-color);
  }
  
  .h3-tit{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    .text{
      width: 100px;
      line-height: 32px;
      text-align: right;
      margin-right: 20px;
    }
    em{
      padding-top: 10px;
      font-size: 12px;
      font-weight: normal;
      color: #666;
    }
  }
}

.select-opt{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  span{
    white-space: nowrap;
    &.gray{
      color: #999;
    }
  }
}
</style>
